define("discourse/plugins/swapd-category-auto-pm/discourse/templates/connectors/category-custom-settings/auto-pm", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.category_auto_pm_enabled}}
    <h3>{{i18n "category_auto_pm.category_setting.title"}}</h3>
    <section class="field">
      <label class="checkbox-label">
  
        {{input
          type="checkbox"
          checked=this.category.custom_fields.auto_pm_enabled
        }}
  
        {{i18n "category_auto_pm.category_setting.checkbox_label"}}
  
      </label>
    </section>
  
    {{#if this.category.custom_fields.auto_pm_enabled}}
      <section class="field">
        <label>
  
          {{i18n "category_auto_pm.category_setting.title_label"}}
  
          {{input
            type="text"
            value=this.category.custom_fields.auto_pm_title
          }}
  
        </label>
      </section>
  
      <section classs="field">
        <label>{{i18n "category_auto_pm.category_setting.message_label"}}</label>
  
        {{d-editor
          value=this.category.custom_fields.auto_pm_message
        }}
      </section>
    {{/if}}
  
  {{/if}}
  */
  {
    "id": "5pRwqm8Y",
    "block": "[[[41,[30,0,[\"siteSettings\",\"category_auto_pm_enabled\"]],[[[1,\"  \"],[10,\"h3\"],[12],[1,[28,[35,2],[\"category_auto_pm.category_setting.title\"],null]],[13],[1,\"\\n  \"],[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"checkbox-label\"],[12],[1,\"\\n\\n      \"],[1,[28,[35,5],null,[[\"type\",\"checked\"],[\"checkbox\",[30,0,[\"category\",\"custom_fields\",\"auto_pm_enabled\"]]]]]],[1,\"\\n\\n      \"],[1,[28,[35,2],[\"category_auto_pm.category_setting.checkbox_label\"],null]],[1,\"\\n\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"category\",\"custom_fields\",\"auto_pm_enabled\"]],[[[1,\"    \"],[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n      \"],[10,\"label\"],[12],[1,\"\\n\\n        \"],[1,[28,[35,2],[\"category_auto_pm.category_setting.title_label\"],null]],[1,\"\\n\\n        \"],[1,[28,[35,5],null,[[\"type\",\"value\"],[\"text\",[30,0,[\"category\",\"custom_fields\",\"auto_pm_title\"]]]]]],[1,\"\\n\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"section\"],[14,\"classs\",\"field\"],[12],[1,\"\\n      \"],[10,\"label\"],[12],[1,[28,[35,2],[\"category_auto_pm.category_setting.message_label\"],null]],[13],[1,\"\\n\\n      \"],[1,[28,[35,6],null,[[\"value\"],[[30,0,[\"category\",\"custom_fields\",\"auto_pm_message\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"h3\",\"i18n\",\"section\",\"label\",\"input\",\"d-editor\"]]",
    "moduleName": "discourse/plugins/swapd-category-auto-pm/discourse/templates/connectors/category-custom-settings/auto-pm.hbs",
    "isStrictMode": false
  });
});